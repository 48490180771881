<template>
  <section class="content">
    <div class="card card-primary">
        <div class="card-body">
            <div class="row">
                <div class="col-10"></div>
                <div class="col-2">
                    <div class="btn-group float-right" ref="toolbar">
                    <button type="button" data-action="read" class="btn btn-default"><i
                            class="fa fa-sync-alt"></i></button>
                    <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="processing" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                    </div>
                </div>
            </div>
            <table class="table table-hover" ref="tbllistsaldo">
                <thead>
                    <tr>
                    <th>SHIPPER</th>
                    <th>SHIPPER_ID</th>
                    <th>TIPE MEMBER</th>
                    <th>TOTAL REKONSILIASI</th>
                    <th>TOTAL SALDO</th>
                    <th>TANDA</th>
                    </tr>
                </thead>
                <tbody @click="handleClick"></tbody>
            </table>
        </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "List Daftar Saldo Shipper",
  data() {
    return {
      errors: [],
      method: "",
      filter: {},
      processing: false
      //   roles: "",
      //   form: {
      //     name: "",
      //     email: "",
      //     pass: "",
      //   },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    downloadXlsx: function (e) {
        const self = this;
        self.processing = true;
        var filter = JSON.parse(JSON.stringify(self.filter));
            filter.category = $("select[name=category]").val();
            filter.q = $("input[name=q]").val();

        var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))).join("&");
        authFetch("/report/shipper/saldo_excell", {
            method: "POST",
            body: data,
        }).then((response) => response.blob())
        .then((blob) => {
            setTimeout(() => {
                self.processing = false;
            }, 1000);
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "data.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        // this.$router.push({ path: "/saldo/shipper/" + e.target.dataset.id });
        const routeData = this.$router.resolve({ path: "/saldo/shipper/" + e.target.dataset.id });
        window.open(routeData.href, '_blank');
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tbllistsaldo, {
      title: "",
      toolbarEl: e.toolbar,
      //   roles: this.$route.params.roles,
      ajax: "/payment/admin/list_saldo",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "nama" },
        { data: "shipper_code" },
        { data: "title" },
        {
          data: "total_rekon",
        sortable: false,
          render: function (data, type, row, meta) {
            return !row.total_rekon ? 0 : row.total_rekon;
          },
        },
        { data: "total_saldo",
          sortable: false 
        },
        {
          data:'marking'
        },
      ],
      filterBy: [0, 1, 2],
      rowCallback: function (row, data) {
        $("td:eq(0)", row).html(
          '<a class="link link-role" data-id="' +
            data.user_id +
            '" style="cursor:pointer;">' +
            data.nama +
            "</a>"
        );
        $("td:eq(4)", row).html("Rp " + formatCurrency(data.total_saldo));
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>